import React, { useEffect, useRef, useState } from 'react';
import LangIcon from '../../../assets/img/idioma-extranjero.png';
import {CommonService} from "../../../services/common.service"

const LangSelect = () => {
    const [languages] = useState([
        {
            name: 'Español',
            value: 'es'
        },
        {
            name: 'English',
            value: 'en'
        }
    ]);

    const [lang, setLang] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const menuRef = useRef(null);

    useEffect(() => {
        const lng = CommonService.getLanguage() === 'es' ? 'es' : 'en';
        setLang(lng);
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        if(document){
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleLanguageChange = (lng) => {
        setLang(lng);
        CommonService.setLanguage(lng)
        setIsOpen(false);
        window.location.reload();
    };

    return (
        <div style={{width: '120px', top: '40px', paddingBottom: '20px'}} className="d-inline-block text-center" ref={menuRef}>
            <div
                className="d-flex align-items-center justify-content-between text-white px-3 py-2 rounded-pill cursor-pointer"
                onClick={() => setIsOpen(!isOpen)}
                style={{ cursor: 'pointer', background: '#334155' }}
            >
                <div className="d-flex align-items-center">
                    <img src={LangIcon} alt="Idioma" width={30} height={20} className='pr-2' />
                    <span>{lang === 'es' ? 'Idioma' : 'Language'}</span>
                </div>
                {/* <span className="dropdown-toggle"></span> */}
            </div>
            {isOpen && (
                <ul className="dropdown-menu show position-absolute w-auto mt-2 shadow rounded" style={{top: 30}}>
                    {languages.map((item) => (
                        <li
                            key={item.value}
                            className="dropdown-item d-flex align-items-center gap-2 px-3 py-2"
                            onClick={() => handleLanguageChange(item.value)}
                            style={{ cursor: 'pointer' }}
                        >
                            <span
                                className={`${
                                    lang === item.value ? 'fw-bold text-primary' : ''
                                }`}
                            >
                                {item.name}
                            </span>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default LangSelect;
