import React, { useState } from "react"
import { connect } from "react-redux"

import Markers from "../../../media/Images"

import imageMan from "../../../media/images/man.png"

import imageWoman from "../../../media/images/woman.png"

import narantiIcon from "../../../media/images/naranti-icon.png"

import logo from "../../../yt.jpg"

import {
    Button,
    Image,
    List,
    Responsive,
    Header,
    Embed
} from "semantic-ui-react"

import { Modal } from "react-bootstrap"
import { useTranslation } from 'react-i18next';

function HelpModal(props) {

    const {t} = useTranslation()
    const [open, setOpen] = useState(false)
    const [buttonHidden, setHide] = useState(true)
    const [buttonIcon, setIcon] = useState('eye')
    const [ownMedia, setOwnMedia] = useState(true)
    const [showTutorial, setShowTutorial] = useState(true)

    const showOwnMedia = () => {
        if (props.media.markersList) {
            setOwnMedia(!ownMedia)
            props.media.markersList.forEach((marker) => {
                let show = false
                if(ownMedia) {
                    setIcon('eye slash')
                    if(marker.code)
                        if (String(marker.code.slice(0, 6)) === String('NAR401'))
                            show = true
                } else {
                    setIcon('eye')
                    show = true
                }
                marker.setVisible(show)
            })
            props.media.mapCircleList.forEach((circle) => {
                let visible = false
                props.media.markersList.forEach((marker) => {
                    if (
                        window.google.maps.geometry.spherical.computeDistanceBetween(
                            marker.getPosition(),
                            circle.getCenter()
                        ) <= circle.getRadius()
                    ) {
                        if (marker.getVisible()) visible = true
                    }
                })
                circle.setVisible(visible)
            })
            props.media.markerClusterer.repaint()
        }
    }

    return (
        <>
            <Responsive maxWidth={499}>
                <Button circular color='orange' icon='help' onClick={() => setOpen(true)}/>
            </Responsive>
            <Responsive minWidth={500}>
                <Button circular color='orange' icon='help' onClick={() => setOpen(true)}/>
            </Responsive>
            
            <Modal
                show={open}
                onHide={() => setOpen(false)}
                scrollable
            >
                <Modal.Header>
                    <Modal.Title onMouseEnter={() => {setHide(false)}} onMouseLeave={() => setHide(true)}>
                        {t("help_modal.help")} <Image src={narantiIcon} size="mini" color="orange" icon={buttonIcon} hidden={buttonHidden} onClick={() => showOwnMedia()}/>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Button.Group>
                        <Button onClick={() => setShowTutorial(true)}>{t("help_modal.tutorial")}</Button>
                        <Button onClick={() => setShowTutorial(false)}>{t("help_modal.elements")}</Button>
                    </Button.Group>
                    <Embed hidden={!showTutorial}
                        id='kfpdO6z6bBQ'
                        placeholder={logo}
                        source='youtube'
                    />
                    <List verticalAlign="middle" hidden={showTutorial}>
                        <List.Item>
                            <Image size="mini" src={Markers.azotea} />
                            <List.Content>
                                <List.Header as="h5">
                                    {t("help_modal.billboard")}
                                </List.Header>
                                <List.Description>
                                    {t(`help_modal.locations`)} {t("help_modal.billboard")}
                                </List.Description>
                            </List.Content>
                        </List.Item>
                        <List.Item>
                            <Image size="mini" src={Markers.muro} />
                            <List.Content>
                                <List.Header as="h5">{t("help_modal.wallscapes")}</List.Header>
                                <List.Description>
                                    {t("help_modal.locations")} {t("help_modal.wallscapes")}
                                </List.Description>
                            </List.Content>
                        </List.Item>
                        <List.Item>
                            <Image size="mini" src={Markers.valla} />
                            <List.Content>
                                <List.Header as="h5">{t("help_modal.billboard_poster")}</List.Header>
                                <List.Description>
                                    {t("help_modal.locations")} {t("help_modal.billboard_poster")}
                                </List.Description>
                            </List.Content>
                        </List.Item>
                        <List.Item>
                            <Image size="mini" src={Markers.pantalla} />
                            <List.Content>
                                <List.Header as="h5">{t("help_modal.digital_billboard")}</List.Header>
                                <List.Description>
                                    {t("help_modal.locations")} {t("help_modal.digital_billboard") }
                                </List.Description>
                            </List.Content>
                        </List.Item>
                        <List.Item>
                            <Image size="mini" src={Markers.landmark} />
                            <List.Content>
                                <List.Header as="h5">{t("help_modal.landmark")}</List.Header>
                                <List.Description>
                                    {t("help_modal.locations")} {t("help_modal.landmark")}
                                </List.Description>
                            </List.Content>
                        </List.Item>
                        <List.Item>
                            <Image size="mini" src={Markers.parabus} />
                            <List.Content>
                                <List.Header as="h5">{t("help_modal.oppis")}</List.Header>
                                <List.Description>
                                    {t("help_modal.locations")} {t("help_modal.oppis")}
                                </List.Description>
                            </List.Content>
                        </List.Item>
                        <List.Item>
                            <Image
                                size="mini"
                                src={Markers["puente peatonal"]}
                            />
                            <List.Content>
                                <List.Header as="h5">
                                    {t("help_modal.pedestrian_bridge_advertising")}
                                </List.Header>
                                <List.Description>
                                    {t("help_modal.locations")} {t("help_modal.pedestrian_bridge_advertising")}
                                </List.Description>
                            </List.Content>
                        </List.Item>
                        <List.Item>
                            <Image
                                size="mini"
                                src={Markers["valla digital"]}
                            />
                            <List.Content>
                                <List.Header as="h5">
                                    {t("help_modal.digital_billboard_poster")}
                                </List.Header>
                                <List.Description>
                                    {t("help_modal.locations")} {t("help_modal.digital_billboard_poster")}
                                </List.Description>
                            </List.Content>
                        </List.Item>
                        <List.Item>
                            <Image size="mini" src={Markers.mediaGroup} />
                            <List.Content>
                                <List.Header as="h5">
                                    {t("help_modal.media_grouping")}
                                </List.Header>
                                <List.Description>
                                    <Responsive maxWidth={499}>
                                        <p title={t("help_modal.media_grouping")}>
                                            {t("help_modal.represents_the_locations_of_various_media_in_the_same_site")}
                                        </p>
                                    </Responsive>
                                    <Responsive minWidth={500}>
                                        <p>
                                            {t("help_modal.represents_the_locations_of_various_media_in_the_same_site")}
                                        </p>
                                    </Responsive>
                                </List.Description>
                            </List.Content>
                        </List.Item>
                    </List>
                    <Header as="h5" textAlign="center">
                        {t("help_modal.contact_advisor")}
                    </Header>
                    <List
                        verticalAlign="top"
                        style={{ textAlign: "center" }}>
                        <List.Item>
                            <Image
                                avatar
                                src={
                                    props.media.currentExecutive.gender === "M"
                                        ? imageMan
                                        : imageWoman
                                }
                            />
                            <List.Content>
                                <List.Header
                                    href={`tel:+52${props.media.currentExecutive.phone}`}
                                    as="a">
                                    {/* {`${props.media.currentExecutive.name}`} */}
                                </List.Header>
                                <List.Description>
                                    <p>
                                        <Button
                                            as='a'
                                            circular 
                                            color='green' 
                                            size='mini' 
                                            icon={{name: 'whatsapp', size:'big'}} 
                                            target='_blank'
                                            href={`
                                                https://wa.me/52${props.media.currentExecutive.officePhoneNumber}?text=${t("main.whastapp_text")}
                                            `}
                                        />
                                    </p>
                                </List.Description>
                            </List.Content>
                        </List.Item>
                    </List>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        color="black"
                        onClick={() => setOpen(false)}
                        className="mr-auto"
                        content={t("help_modal.close")}
                    />
                    <Button
                        as="a"
                        color="green"
                        icon="phone"
                        labelPosition="right"
                        content={t("help_modal.call_advisor")}
                        href={`tel:+52${props.media.currentExecutive.phone}`}
                    />
                </Modal.Footer>
            </Modal>
        </>
    )
}

const mapStateToProps = state => state

export default connect(mapStateToProps)(HelpModal)
