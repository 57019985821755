import React, { Component } from "react"
import { DEFAULT } from "../../../config"
import { connect } from "react-redux"
import { Map, Marker, GoogleApiWrapper } from "google-maps-react"

import Markers from "../../../media/Images"

import notImage from "../../../media/images/sinimagen.jpg"

import { Icon, Grid, Button, Image, Card, Label, Header, Responsive, Checkbox } from "semantic-ui-react"

import ModalImage from "react-modal-image"

import * as FileSaver from "file-saver"
import * as XLSX from "xlsx"

import { Modal } from "react-bootstrap"

import {
    handleSetFavoriteMedium,
    handleShowMediaModal,
    handleSetCurrentMedium,
    handleShowContactModal,
    handleRemoveFavoriteMedium,
    handleSetViewOnlyFavoriteList
} from "../../../actions/mediaAction"
import { deleteFavoritesOnLocal } from "../../../utils/common"
import { withTranslation } from 'react-i18next';

class MediaModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            favoriteList: false,
        }
        
        this.renderMediaDescription = this.renderMediaDescription.bind(this)
        this.getCardMedium = this.getCardMedium.bind(this)
        this.closeMediaModal = this.closeMediaModal.bind(this)
        this.printFavoriteMediaList = this.printFavoriteMediaList.bind(this)
        this.exportFavoriteMediaList = this.exportFavoriteMediaList.bind(this)
        this.contactModal = this.contactModal.bind(this)
        this.getCardinality = this.getCardinality.bind(this)
        this.viewSelectedMedia = this.viewSelectedMedia.bind(this)
        this.ref = React.createRef()
    }

    componentDidMount() {}

    viewSelectedMedia (e, { name, checked }) {
        console.log(checked)
        this.props.dispatch(handleSetViewOnlyFavoriteList(checked))
        console.log(this.props.media)
        
        this.props.media.markersList.forEach(marker => {
            const wasFound = this.props.media.favoriteMediaList.some(favoriteMedium => favoriteMedium.full_code === marker.code);
            
            if (checked) {
                marker.setVisible(wasFound);
            } else {
                marker.setVisible(true);
            }
        })
        this.props.media.markerClusterer.repaint();
    }

    printFavoriteMediaList() {
        const ids = this.props.media.favoriteMediaList.map(
            favorite => favorite.full_code
        )
        
        window.open(`/${this.props.media.country.toLowerCase()}/ooh/printMedia?ids=${ids}`, "_blank")
    }

    exportFavoriteMediaList() {
        const jsonData = this.props.media.favoriteMediaList.map(medium => {
            let cardinality = this.getCardinality(medium.cardinality)
            return {
                Código: medium.full_code,
                Ubicación: `${medium.city.name}, ${medium.state.name}.`,
                Dirección: medium.address,
                Tipo: medium.type.name,
                Medidas: `${medium.width} x ${medium.height} mts`,
                Cardinalidad: cardinality,
                Vista: medium.view == "Crossed" ? "Curzada" : "Natural",
                Iluminación: medium.illuminated ? "Sí" : "No",
                "Nivel de Impacto": medium.impact_level,
                "Nivel Socioeconómico": medium.socioeconomic_level,
                "Impactos Mensuales": `${medium.monthly_impacts} / mes`,
                longitude: medium.longitude,
                lattitude: medium.lattitude
            }
        })

        const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
        const fileExtension = ".xlsx"

        const ws = XLSX.utils.json_to_sheet(jsonData)
        const wscols = [
            { wch: 15 },
            { wch: 40 },
            { wch: 13 },
            { wch: 10 },
            { wch: 10 },
            { wch: 10 },
            { wch: 10 },
            { wch: 10 },
            { wch: 17 },
            { wch: 12 },
            { wch: 17 },
            { wch: 17 },
            { wch: 17 },        ]
        
        ws["!cols"] = wscols
        for (let i = 2; i <= jsonData.length + 1; i++) {
            ws[`A${i}`].l = {
                Target: `${DEFAULT.sistema}/${ws[`A${i}`].v}`,
                Tooltip: ws[`A${i}`].v,
            }
        }
        const wb = { Sheets: { medios: ws }, SheetNames: ["medios"] }
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" })
        const data = new Blob([excelBuffer], { type: fileType })
        FileSaver.saveAs(data, "medios" + fileExtension)
    }

    contactModal() {
        this.props.dispatch(handleShowContactModal(true))
        this.props.dispatch(handleShowMediaModal(false))
    }

    closeMediaModal() {
        this.props.dispatch(handleShowMediaModal(false))
        window.setTimeout(() => {
            this.props.dispatch(handleSetCurrentMedium(null))
        }, 200)
    }

    handleAddToFavorite(medium) {
        this.props.dispatch(handleSetFavoriteMedium(medium))
    }

    getCardinality(cardinality) {
        const { t } = this.props;
        switch (cardinality) {
            case "north":
                return t("media_details.cardinality.north");
            case "south":
                return t("media_details.cardinality.south");
            case "east":
                return t("media_details.cardinality.east");
            case "west":
                return t("media_details.cardinality.west");
            case "northwest":
                return t("media_details.cardinality.northwest");
            case "northeast":
                return t("media_details.cardinality.northeast");
            case "southeast":
                return t("media_details.cardinality.southeast");
            case "southwest":
                return t("media_details.cardinality.southwest");
            default:
                return t("media_details.cardinality.default");
        }
    }    

    getCardMedium(medium, index, fav = false) {
        const {t} = this.props
        let favoritePicture = medium.pictures
        let cardinality = this.getCardinality(medium.cardinality)
        
        const urlPicture = favoritePicture
            ? `${DEFAULT.storage}${favoritePicture.path}`
            : notImage
    
        const streetView =
            medium.streetview != null ? (
                <a
                    href={medium.streetview}
                    target="_blank"
                    rel="noopener noreferrer">
                    <Icon name="street view" size="large"/>
                </a>
            ) : (
                ""
            )
    
        return (
            <Card centered key={`favorite_${index}`}>
                <Label attached='top' color="orange">{medium.full_code}</Label>
                <Image
                    label={{
                        as: 'a', 
                        corner: 'right', 
                        icon: 'trash', 
                        hidden: !fav,
                        className:'red',
                        onClick: () => {
                            this.props.dispatch(handleRemoveFavoriteMedium(medium));
                            deleteFavoritesOnLocal(medium)
                        }
                    }}
                />
                <ModalImage 
                    small={urlPicture}
                    large={urlPicture}
                />
                <Card.Content>
                    <Card.Header as="h6">{`${medium.city.name}, ${medium.state.name}.`}</Card.Header>
                    <Card.Meta>
                        <span className="date">{t("media_details.specifications")}</span>
                    </Card.Meta>
                    <Card.Description>
                        <b>{t("media_details.address")}:</b> {`${medium.address}, C.P. ${medium.postal_code}`}
                        <br />
                        <b>{t("media_details.type")}:</b> {medium.type.name}
                        <br />
                        <b>{t("media_details.measurements")}:</b> {`${medium.width} x ${medium.height} mts`}
                        <br />
                        <b>{t("media_details.cardinality.title")}:</b> {cardinality}
                        <br />
                        <b>{t("media_details.view")}:</b> {medium.view == "Crossed" ? t("media_details.crossed") : t("media_details.natural")}
                        <br />
                        <b>{t("media_details.lighting")}:</b> {medium.illuminated ? t("media_details.yes") : t("media_details.no")}
                        <br />
                        <b>{t("media_details.impact_level")}:</b> {medium.impact_level}
                        <br />
                        <b>{t("media_details.socioeconomic_level")}:</b> {medium.socioeconomic_level}
                        <br />
                        <b>{t("media_details.monthly_impacts")}:</b> {medium.monthly_impacts} / {t("media_details.per_month")}
                    </Card.Description>
                </Card.Content>
                <Card.Content extra>
                    {streetView}
                    <label>
                        <Icon name="map marker" />
                        {`Lat: ${medium.lattitude}`} {`Long: ${medium.longitude}`}
                    </label>
                </Card.Content>
            </Card>
        )
    }
    

    getMarker(medium, index) {
        const type = medium.type.name
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase()
        return (
            <Marker
                key={`medium_${index}`}
                position={{
                    lat: medium.lattitude,
                    lng: medium.longitude,
                }}
                icon={{
                    url: Markers[type],
                    anchor: new this.props.google.maps.Point(16, 16),
                    scaledSize: new this.props.google.maps.Size(32, 32),
                }}
                onClick={(marker) => {
                    let panorama = new this.props.google.maps.StreetViewPanorama(
                        this.refs.map_fav.mapRef.current,
                        {
                            position: marker.position,
                            enableCloseButton: true,
                            pov: {
                                heading: 135,
                                pitch: 20
                            }
                        }
                    )
                    panorama.setVisible(true);
                }}
                name={type}
            />
        )
    }

    renderMediaDescription() {
        const {t} = this.props
        const medium = this.props.media.currentMedium
        if (medium) {
            return (
                <Grid columns='equal' padded>
                    <Grid.Column width={16} textAlign="center" className={window.screen.availWidth < 500 && 'd-flex flex-column'}>
                        <Button
                            content={t("media_details.add_to_cart")}
                            icon="shopping cart"
                            color="yellow"
                            labelPosition="right"
                            className={window.screen.availWidth < 500 && 'm-2'}
                            onClick={this.handleAddToFavorite.bind(
                                this,
                                medium
                            )}
                        />
                        <Button
                            content={t("media_details.request_a_quote")}
                            icon="envelope outline"
                            color="green"
                            labelPosition="right"
                            className={window.screen.availWidth < 500 && 'm-2'}
                            onClick={this.contactModal}
                        />
                        <Button
                            content={t("media_details.download_pdf")}
                            icon="file pdf"
                            color="red"
                            labelPosition="right"
                            className={window.screen.availWidth < 500 && 'm-2'}
                            onClick={() => window.open(`/${this.props.media.country.toLowerCase()}/ooh/${medium.full_code}`, '_blank')}
                        />
                    </Grid.Column>
                    <Grid.Column width={16} textAlign="center">
                        <Button 
                            as="a" 
                            icon="phone"
                            href={`tel:+52${this.props.media.currentExecutive.phone}`}
                            basic 
                            color="green"
                            content={`${this.props.media.currentExecutive.phoneFormatted}`}
                        />
                        <Button
                            as='a'
                            basic
                            color='green' 
                            icon={{name: 'whatsapp'}} 
                            target='_blank'
                            href={`
                                https://wa.me/52${this.props.media.currentExecutive.officePhoneNumber}?text=${t("main.whastapp_text")}
                            `}
                        />
                    </Grid.Column>
                    <Responsive 
                        as={Grid.Column}
                        className="favorite"
                        width={16}
                        maxWidth={499}
                    >
                        {this.getCardMedium(medium, 0)}
                    </Responsive>
                    <Responsive 
                        as={Grid.Column}
                        className="favorite"
                        width={8}
                        minWidth={500}
                    >
                            {this.getCardMedium(medium, 0)}
                    </Responsive>
                    <Responsive 
                        as={Grid.Column}
                        className="favorite"
                        width={8}
                        minWidth={500}
                    >
                        <Map
                            ref="map_fav"
                            google={window.google}
                            initialCenter={{
                                lat: medium.lattitude,
                                lng: medium.longitude,
                            }}
                            rotateControl={false}
                            mapTypeControl={false}
                            panControl={false}
                            fullscreenControl={false}
                            // streetViewControl={false}
                            zoom={16}
                            style={{
                                height: "70%",
                                width: "85%",
                            }}>
                            {this.getMarker(medium, "fav_0")}
                        </Map>
                    </Responsive>
                </Grid>
            )
        } else {
            return (
                <Grid columns="equal" padded>
                    <Grid.Column width={16} textAlign="center">
                        <Button 
                            as="a" 
                            icon="phone"
                            href={`tel:+52${this.props.media.currentExecutive.phone}`}
                            basic 
                            color="green"
                            content={t("media_details.call")}
                        />
                        <Button
                            as='a'
                            basic
                            color='green' 
                            icon={{name: 'whatsapp'}} 
                            target='_blank'
                            href={`
                                https://wa.me/52${this.props.media.currentExecutive.officePhoneNumber}?text=${t("main.whastapp_text")}
                            `}
                        />
                    </Grid.Column>
                    {   
                        this.props.media.favoriteMediaList.length ?
                            this.props.media.favoriteMediaList.map((medium, index) =>
                                this.getCardMedium(medium, index, true)
                            )
                        :
                        <Header as="h3">{t("media_details.no_items_found")}</Header>
                    }
                </Grid>
            )
        }
    }

    render() {
        const {t} = this.props
        let isFavoriteList = !this.props.media.currentMedium
        return (
            <div>
                <Modal
                    size="lg"
                    centered
                    show={this.props.media.showMediaModal}
                    onHide={this.closeMediaModal}
                    scrollable
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {isFavoriteList ? t("media_details.cart") : t("media_details.details")}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="p-2" style={{overflowX:'hidden'}} ref={this.ref}>
                        {
                            isFavoriteList ? 
                                <div className="m-2 p-3">
                                    <Checkbox
                                        className="checkbox-view-current"
                                        title={t("media_details.view_elected_media")}
                                        label={t("media_details.view_elected_media")}
                                        onChange={ this.viewSelectedMedia }
                                        data-type="type"
                                        defaultChecked={this.props.media.viewOnlyFavoriteList}
                                    />
                                </div>
                             : <></>
                        }
                        {
                            isFavoriteList && window.screen.availWidth > 500? 
                                <Label as="a" content={t("media_details.export_excel_quote")} onClick={this.exportFavoriteMediaList} ribbon='right' color="green" icon="file excel" />
                             : <></>
                        }
                        
                        {this.renderMediaDescription()}
                        <div className="flex w-100 p-2">
                            {
                                isFavoriteList && window.screen.availWidth < 500 &&
                                <div>
                                    <Button
                                        content={t("media_details.export_excel_quote")}
                                        color="green"
                                        icon="file excel outline"
                                        labelPosition="right"
                                        className="w-100"
                                        onClick={this.exportFavoriteMediaList}
                                    />
                                </div>
                            }
                        </div>
                        
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="d-flex">
                            {
                                window.screen.availWidth < 500 ? (
                                <Button
                                    color="red"
                                    onClick={this.closeMediaModal}
                                    icon='times'
                                />) : (
                                <Button
                                    color="black"
                                    onClick={this.closeMediaModal}
                                    content='Cerrar'
                                />)
                            }
                            {isFavoriteList ? (
                                <>
                                    {/* <Button
                                        color="green"
                                        icon="file excel"
                                        textAlign="right"
                                        onClick={this.exportFavoriteMediaList}
                                    />*/}
                                    <Button
                                        content={t("media_details.download_quote")}
                                        color="orange"
                                        icon="print"
                                        labelPosition="right"
                                        onClick={this.printFavoriteMediaList}
                                    />
                                    <Button
                                        content={t("media_details.request_a_quote")}
                                        color="orange"
                                        icon="envelope outline"
                                        labelPosition="right"
                                        onClick={this.contactModal}
                                    /> 
                                    
                                    
                                </>
                            ) : (
                                <></>
                            )}
                        </div>
                        {/* <div className="row">
                            <div className="col-12">
                            { 
                                window.screen.availWidth < 500 && isFavoriteList &&(
                                    <Checkbox
                                        className="checkbox-view-current"
                                        title={"Ver medios seleccionados"}
                                        label={'Ver medios seleccionados'}
                                        onChange={ this.viewSelectedMedia }
                                        data-type="type"
                                        defaultChecked={this.props.media.viewOnlyFavoriteList}
                                    />
                                )
                            }
                            </div>
                        </div> */}
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = state => state

export default connect(mapStateToProps)(
    GoogleApiWrapper({
        apiKey: DEFAULT.MAPS_API_KEY,
        libraries: ["places"],
    })(withTranslation()(MediaModal))
)
